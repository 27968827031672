import React from 'react';

import { Layout } from '../components/shared/Layout';
import { Seo } from '../components/shared/Seo';
import { Page } from '../components/shared/Page';
import { Button } from '../components/shared/Button';

const PodporovaneBanky = () => {
  const close = () => window.open('', '_self').close();

  return (
    <Layout theme="in-app">
      <Seo title="Podporované banky" />
      <Page
        className="md:w-9/12 lg:w-8/12 xl:w-1/2 mx-auto space-y-8"
        title="Podporované banky"
      >
        <section className="px-8 space-y-4">
          <p>Aktuálně dokážeme zpracovat výpisy těchto bank:</p>
          <ul className="ml-8 list-disc">
            <li>Česká spořitelna</li>
            <li>Moneta Money Bank</li>
            <li>AirBank</li>
            <li>ČSOB</li>
            <li>Komerční Banka</li>
            <li>Fio Banka</li>
            <li>Raiffeisenbank</li>
            <li>Equa Bank</li>
            <li>UniCredit Bank</li>
            <li>mBank</li>
          </ul>
          <p>Seznam postupně rozšiřujeme a stále přidáváme nové banky.</p>
        </section>

        <Button
          type="button"
          onClick={close}
          className="mx-auto my-6 px-16"
          border="normal"
          data-testid="dashboard-start-onboarding"
        >
          vrátit se zpět
        </Button>
      </Page>
    </Layout>
  );
};

export default PodporovaneBanky;
